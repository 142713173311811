<template>
    <section id="tour-list">
                <div class="card">
                    <div class="grid">
                        <div class="col-12 xl:col-12">
                            <div class="flex flex-grow-1 align-items-center justify-content-center my-2">
                                <div class="flex flex-grow-1 align-items-center justify-content-start">
                                    <div class="text-xl font-semibold">Tours</div>
                                </div>
                                <div class="flex flex-grow-1 align-items-center justify-content-end">
                                    <Button label="Create New" icon="pi pi-plus" class="p-button-success mr-2" @click="createTour" />
                                </div>
                            </div>
                            <hr class="mb-3 mx-0 border-top-1 border-none surface-border mt-auto" />
                            <div v-if="pageLoading">
                                <div class="grid p-formgrid mx-3 mb-4" v-for="i in 4" :key="i">
                                    <div class="col-12 md:col-4">
                                        <Skeleton class="mb-2" height="12rem"/>
                                        <Skeleton height="3rem" />
                                    </div>
                                    <div class="col-12 md:col-8">
                                        <div class="grid">
                                            <div class="col-12">
                                                <Skeleton height="2.75rem" width="100%"/>
                                                <Skeleton height="1.25rem" width="60%" class="mt-1 mb-2"/>
                                            </div>
                                            <div class="col-12 py-0">
                                                <div class="grid">
                                                    <div class="col-4"><Skeleton height="10rem"/></div>
                                                    <div class="col-3"><Skeleton height="10rem"/></div>
                                                    <div class="col-5"><Skeleton height="10rem"/></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="grid-nogutter" v-else>
                                <div class="grid">
                                    <div class="col-12 md:col-4">
                                        <div class="field mb-0" >
                                            <label for="type">Type</label>
                                            <Dropdown
                                                class="w-full"
                                                id="type"
                                                v-model="type"
                                                :options="types"
                                                optionLabel="name"
                                                optionValue="id"
                                                :filter="true"
                                                placeholder="Select type"
                                            />
                                        </div>
                                    </div>
                                    <div class="col-12 md:col-4">
                                        <div class="field mb-0" >
                                            <label for="catagory">Catagory</label>
                                            <Dropdown
                                                class="w-full"
                                                id="catagory"
                                                v-model="catagory"
                                                :options="catagories"
                                                optionLabel="name"
                                                optionValue="id"
                                                :filter="true"
                                                placeholder="Select catagory"
                                            />
                                        </div>
                                    </div>
                                    <div class="col-12 md:col-4">
                                        <div class="field mb-0">
                                            <label for="QueryDate">Date</label>
                                            <Calendar
                                                class="w-full"
                                                id="QueryDate"
                                                v-model="QueryDate"
                                                dateFormat="dd-mm-yy"
                                                :showButtonBar="true"
                                                selectionMode="single"
                                                :showIcon="true"
                                                placeholder="Select a date"
                                            />
                                        </div>
                                    </div>
                                    <div class="col-12 md:col-4 md:col-offset-4">
                                        <div class="field mb-0" >
                                            <label for="status">Status</label>
                                            <Dropdown
                                                class="w-full"
                                                id="status"
                                                v-model="filterStatus"
                                                :options="statusOptions"
                                                optionLabel="name"
                                                optionValue="value"
                                                :filter="true"
                                                placeholder="Select Status"
                                            />
                                        </div>
                                    </div>
                                    <div class="col-12 md:col-4 md:my-5 mb-3">
                                        <div class="flex flex-wrap ">
                                            <div class="flex-1 flex align-items-center justify-content-center mx-2">
                                                <Button label="Clear" icon="pi pi-filter-slash" class="p-button-raised p-button-text w-full" @click="clearFilters"></Button>
                                            </div>
                                            <div class="flex-1 flex align-items-center justify-content-center mx-2">
                                                <Button label="Apply" icon="pi pi-filter" class="p-button-raised p-button-text w-full" @click="getFilteredList"></Button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <template v-if="tours.data.length > 0">
                                    <div class="col-12">
                                        <Dialog header="Confirmation" v-model:visible="displayDeleteConfirmation" :style="{width: '350px'}" :modal="true">
                                            <div class="confirmation-content">
                                                <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem" />
                                                <span>Are you sure you want to delete?</span>
                                            </div>
                                            <template #footer>
                                                <Button label="No" icon="pi pi-times" @click="closeDeleteConfirmation" class="p-button-text"/>
                                                <Button label="Yes" icon="pi pi-check" @click="deleteTour" class="p-button-raised p-button-danger" autofocus />
                                            </template>
                                        </Dialog>
                                        <Dialog header="Change Status" v-model:visible="displayStatusDialog" :breakpoints="{'960px': '65vw', '640px': '90vw'}" :style="{width: '40vw'}" :modal="true">
                                            <div class="confirmation-content">
                                                <div class="field" >
                                                    <label for="status">Status</label>
                                                    <Dropdown
                                                        class="w-full"
                                                        id="status"
                                                        v-model="status"
                                                        :options="statusOptions"
                                                        optionValue="value"
                                                        optionLabel="name"
                                                        placeholder="Select an Option"
                                                        showClear
                                                    />
                                                </div>
                                            </div>
                                            <template #footer>
                                                <Button label="Cancle" icon="pi pi-times" @click="closeStatusDialog" class="p-button-text"/>
                                                <Button label="Confirm" :disabled="!status" icon="pi pi-check" @click="updateStatus" class="p-button-raised p-button" autofocus />
                                            </template>
                                        </Dialog>
                                    </div>
                                    <div class="col-12">
                                        <div v-for="(tour, i) in tours.data" :key="i">
                                            <div class="grid mb-3 p-2 tour-item">
                                                <div class="col-12 md:col-4">
                                                    <img class="w-full border-round" :src="constant.imgUrl + tour.first_image" :alt="tour.title" />
                                                    <div class=" mt-2">
                                                        <Button class=" w-full p-text-bold px-8" type="button" label="Action" @click="toggle($event, tour)" aria-haspopup="true" aria-controls="overlay_menu"/>
                                                        <Menu id="overlay_menu" ref="menu" :model="items" :popup="true" />
                                                    </div>
                                                </div>
                                                <div class="col-12 md:col-8">
                                                    <div class="mb-2">
                                                        <div class="flex justify-content-between">
                                                            <div class="flex flex-wrap align-items-center justify-content-center">
                                                                <div class="text-2xl text-primary font-semibold cursor-pointer tour-title">
                                                                    <router-link :to="{ name: 'details', params: { slug: tour.slug, id: tour.id } }">{{ tour.title }}</router-link>
                                                                </div>
                                                            </div>
                                                            <div class="flex align-items-center justify-content-center">
                                                                <Chip v-if="tour.status == 0" label="Pending" icon="pi pi-exclamation-circle" class="custom-chip5 px-2" />                                                                    
                                                                <Chip v-if="tour.status == 1" label="Upcoming" icon="pi pi-calendar" class="custom-chip3 px-2" />                                                                    
                                                                <Chip v-if="tour.status == 2" label="Ongoing" icon="pi pi-bell" class="custom-chip px-2" />
                                                                <Chip v-if="tour.status == 3" label="Completed" icon="pi pi-check" class="custom-chip2 px-2" />
                                                                <Chip v-if="tour.status == -1" label="Paused" icon="pi pi-calendar-times" class="custom-chip4 px-2" />
                                                                <Chip v-if="tour.status == -2" label="Suspended" icon="pi pi-ban" class="custom-chip4 px-2" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="font-light mb-2">
                                                        <i class="pi pi-map-marker"></i><span class="ml-2">{{ tour.destination.line + ', ' + tour.destination.district }}</span>
                                                    </div>
                                                    <div class="flex flex-wrap">
                                                        <div class="flex-grow-1 flex align-items-center card m-1 p-2">
                                                            <div>
                                                                <div class="m-2">
                                                                    <i class="pi pi-arrow-right"></i>
                                                                    <span class="ml-2">{{ formatDate(tour.start_date) }}</span>
                                                                </div>
                                                                <div class="m-2">
                                                                    <i class="pi pi-arrow-left"></i>
                                                                    <span class="ml-2">{{ formatDate(tour.end_date) }}</span>
                                                                </div>
                                                                <Divider></Divider>
                                                                <div class="m-2">
                                                                <div>Booking deadline</div>
                                                                <div class="font-semibold text-orange-700">{{ formatDate(tour.booking_deadline) }}</div>
                                                            </div>
                                                            </div>
                                                        </div>
                                                        <div class="flex-grow-1 flex align-items-center card m-1 p-2">
                                                            <div>
                                                                <div class="flex">
                                                                    <div class="flex flex-column">
                                                                        <div class="flex align-items-end flex-wrap font-semibold text-xl">
                                                                            <template v-if="tour.discounted_price_adults == 0">
                                                                                <span class="flex align-items-end justify-content-center text-primary mr-1">BDT</span>
                                                                                <span class="flex align-items-end justify-content-center text-primary mx-1">{{ tour.price_adults.toLocaleString('en-BD') }}</span>
                                                                            </template>
                                                                            <template v-else>
                                                                                <span class="flex align-items-end justify-content-center text-base old-price">
                                                                                    <span>BDT</span>
                                                                                    <span>{{ tour.price_adults.toLocaleString('en-BD') }}</span>
                                                                                </span>
                                                                                <span class="flex align-items-end justify-content-center text-primary mx-2">
                                                                                    <span class="mr-1">BDT</span>
                                                                                    <span class="">{{ tour.discounted_price_adults.toLocaleString('en-BD') }}</span>
                                                                                </span>
                                                                            </template>
                                                                        </div>
                                                                        <div class="flex text-sm mt-1">Per Adult</div>
                                                                    </div>
                                                                </div>
                                                                <Divider></Divider>
                                                                <div class="flex">
                                                                    <div class="flex flex-column">
                                                                        <div class="flex align-items-end flex-wrap font-semibold text-xl">
                                                                            <template v-if="tour.discounted_price_children == 0">
                                                                                <span class="flex align-items-end justify-content-center text-primary mr-1">BDT</span>
                                                                                <span class="flex align-items-end justify-content-center text-primary mx-1">{{ tour.price_children.toLocaleString('en-BD') }}</span>
                                                                            </template>
                                                                            <template v-else>
                                                                                <span class="flex align-items-end justify-content-center text-base old-price">
                                                                                    <span>BDT</span>
                                                                                    <span>{{ tour.price_children.toLocaleString('en-BD') }}</span>
                                                                                </span>
                                                                                <span class="flex align-items-end justify-content-center text-primary mx-2">
                                                                                    <span class="mr-1">BDT</span>
                                                                                    <span class="">{{ tour.discounted_price_children.toLocaleString('en-BD') }}</span>
                                                                                </span>
                                                                            </template>
                                                                        </div>
                                                                        <div class="flex text-sm mt-1">Per Children</div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="flex-grow-1 flex align-items-center card m-1 p-2">
                                                            <div>
                                                                <div class="m-2 mb-0">
                                                                    <i class="pi pi-users"></i>
                                                                    <span class="ml-2"><span class="font-semibold text-primary">{{ tour.seat_total }}</span> seats total</span>
                                                                </div>
                                                                <div class="m-2">
                                                                    <i class="pi pi-user-plus"></i>
                                                                    <span class="ml-2"><span class="font-semibold text-primary">{{ (tour.seat_total - tour.seat_booked) }}</span> seats available</span>
                                                                </div>
                                                                <Divider></Divider>
                                                                <div class="ml-2">
                                                                    <span class="text-sm">Manager</span><br>
                                                                    <span class="font-semibold text-primary">{{ tour.manager.name }}</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div> 
                                        </div>
                                        <Paginator v-model:first="tours.from" :rows="paginationValue" :totalRecords="tours.total" :rowsPerPageOptions="[10,20,50]" @page="onPage" />
                                    </div>
                                </template>
                                <template v-else>
                                    <div class="col">
                                        <div class="card">
                                            <div class="flex align-items-stretch justify-content-center flex-wrap card-container" style="min-height: 250px">
                                                <div class="flex align-items-center justify-content-center">
                                                    <Message severity="info" :closable="false">No tour found!</Message>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </template>
                            </div>
                        </div>
                    </div>
                </div>
    </section>
</template>

<script>
import { ref } from 'vue';
import moment from 'moment';
import Axios from 'axios';
import { useRoute, useRouter } from 'vue-router';
import { useToast } from 'primevue/usetoast';
import Constant from '../../data/constant';

export default {
  setup(){
    const router = useRouter();
    const route = useRoute();
    const pageLoading = ref(true);
    const toast = useToast();
    const constant =  ref(new Constant());
    const currentDate = new Date();

    let tours = ref([]);
    const menu = ref();
    const seleectedTour = ref();
    const displayDeleteConfirmation = ref(false);
    const displayStatusDialog = ref(false);

    const paginationValue = ref(10);
    const pageValue = ref();
    const types = ref();
    const type = ref();
    const catagories = ref();
    const catagory = ref();
    const filterStatus = ref();
    const QueryDate = ref();

    const items = ref([
        {
            label: 'Details',
            icon: 'pi pi-list',
            command: () => {
                showToast("error", "Error", "update check");
                router.push({name:'details', params:{slug:seleectedTour.value.slug}});
            }
        },
        {
        label: 'Update',
        icon: 'pi pi-pencil',
        command: () => {
            router.push({name:'u-basic', params:{slug:seleectedTour.value.slug}});
        }
        },
        {
            label: 'Duplicate',
            icon: 'pi pi-clone',
            command: () => {
                router.push({path: '/tours/duplicate', query: { slug:seleectedTour.value.slug }});
            }
        },
        {
            label: 'Delete',
            icon: 'pi pi-trash',
            command: () => {
                displayDeleteConfirmation.value = true;
            }
        },
        {
            label: 'Status',
            icon: 'pi pi-th-large',
            command: () => {
                displayStatusDialog.value = true;
            }
        },
    ]);

    const toggle = (event, tour) => {
      seleectedTour.value = tour;
      menu.value.toggle(event);
    };

    const status = ref();

    const statusOptions = ref([
        {value: '1', name: 'Upcoming'},
        {value: '-1', name: 'Paused'},
        {value: '-2', name: 'Suspended'},
        {value: '3', name: 'Completed'},
        {value: '2', name: 'Ongoing'},
        {value: '0', name: 'Pending'},
    ]);

    const getActiveQuerys = () => {
        if(route.query.page != null){
            pageValue.value = route.query.page;
        }
        if(route.query.pagination != null){
            paginationValue.value = parseInt(route.query.pagination);
        }
        if(route.query.type != null){
            type.value = route.query.type;
        }
        if(route.query.catagory != null){
            catagory.value = route.query.catagory;
        }
        if(route.query.status != null){
            filterStatus.value = route.query.status;
        }
        if(route.query.date != null){
            QueryDate.value = new Date(route.query.date);
        }
    };
    getActiveQuerys();

    const getTourData = () =>{
      Axios
      .get("api/admin/tour/list",{
            params: {
                page: pageValue.value,
                pagination: paginationValue.value,
                type: type.value,
                catagory: catagory.value,
                status: filterStatus.value,
                date: QueryDate.value == null ? null : moment(QueryDate.value).format("YYYY-MM-DD"),
            }
        })
      .then((res) =>{
        pageLoading.value = false;
        if (res.data.response == "success") {
            tours.value = res.data.data;
            tours.value.from = tours.value.from-1;
        }
        else{
            console.log(res.data.message);
        }
      })
      .catch((err) =>{
          console.log(err);
          pageLoading.value = false;
      })
    }
    getTourData();

    const getTypesCategorys = () =>{
        Axios
        .get("api/data/tour-types-and-categories")
        .then((res) =>{
            if (res.data.response == "success") {
                catagories.value = res.data.data.categories;
                types.value = res.data.data.types;
            }
            else{
                console.log(res.data.message);
            }
        })
        .catch((err) =>{
            console.log(err);
        })
    };
    getTypesCategorys();
    
    const closeDeleteConfirmation = () => {
        displayDeleteConfirmation.value = false;
    };

    const closeStatusDialog = () => {
        displayStatusDialog.value = false;
        status.value = null;
    };

    const showToast = (severity, summary, detail) => {
        toast.add({severity: severity, summary: summary, detail: detail, life: 5000});
    };

    const formatDate = (rawDate) => {
        return moment(rawDate).format("MMMM Do, YYYY");
    };

    const formatTime = (rawDate) => {
        return moment(rawDate).format("YYYY-MM-DD");
    };

    const createTour = () =>{
        router.replace({path: '/tours/create'})
    }

    const deleteTour = () => {
      Axios
        .delete("api/admin/tour/delete/"+seleectedTour.value.id)
        .then(res => {
            if(res.data.response == "success"){
                getTourData();
            }
            else{
                showToast("error", "Error", res.data.message);
            }
        })
        .catch(err => {
            showToast("error", "Error", "Something went wrong. Try again!");
            console.log(err);
        })
        displayDeleteConfirmation.value = false;
    };

    const updateStatus = () => {
        const formData = new FormData();

        formData.append("status", status.value);
        formData.append("_method", "PUT");

        Axios
        .post("api/admin/tour/update-status/"+seleectedTour.value.id, formData, {
                config: {
                    headers: {'Content-Type': 'multipart/form-data' }
                }
            })
        .then(res => {
            if(res.data.response == "success"){
                toast.add({severity:'info', summary:'Confirmed', detail:'Status Updated', life: 3000});
                getTourData();
            }
            else{
                showToast("error", "Error", res.data.message);
            }
            status.value = null;
        })
        .catch(err => {
            showToast("error", "Error", "Something went wrong. Try again!");
            console.log(err);
        })
        displayStatusDialog.value = false;
    }

    const getFilteredList = () => {
        pageLoading.value = true;
        // showFilterMenu.value = false;

        var queryParams = {};
        if(type.value != null){
            queryParams.type = type.value;
        }
        if(catagory.value != null){
            queryParams.catagory = catagory.value;
        }
        if(filterStatus.value != null){
            queryParams.status = filterStatus.value;
        }
        if(QueryDate.value != null){
            queryParams.date = moment(QueryDate.value).format("YYYY-MM-DD");
        }

        router.replace({ name: 'tours', query: { ...queryParams } });

        getTourData();
    };

    const clearFilters = () => {
        pageLoading.value = true;
        // showFilterMenu.value = false;

        type.value = null;
        catagory.value = null;
        filterStatus.value = null;
        QueryDate.value = null;
        router.replace();

        getTourData();
    };

    const onPage = (event) => {
        pageValue.value = event.page + 1;
        paginationValue.value = event.rows;
        pageLoading.value = true;
        // showFilterMenu.value = false;

        var queryParams = {};
        if(pageValue.value != null){
            queryParams.page = pageValue.value;
        }
        if(paginationValue.value != null){
            queryParams.pagination = paginationValue.value;
        }
        router.replace({ name: 'tours', query: { ...queryParams } });

        getTourData();
    }

    return{
      tours,
      currentDate,
      createTour,
      getTourData,
      menu,
      items,
      toggle,
      status,
      statusOptions,
      updateStatus,
      pageLoading,
      displayDeleteConfirmation,
      closeDeleteConfirmation,
      displayStatusDialog,
      closeStatusDialog,
      deleteTour,
      formatDate,
      formatTime,
      paginationValue,
      onPage,
      constant,
      types,
      type,
      catagories,
      catagory,
      filterStatus,
      QueryDate,
      getFilteredList,
      clearFilters
    }
  }
}
</script>

<style lang="scss" scoped>
.p-chip.custom-chip {
    background: var(--green-500);
    color: var(--primary-color-text);
}
.p-chip.custom-chip2 {
    background: var(--yellow-500);
    color: var(--primary-color-text);
}
.p-chip.custom-chip3 {
    background: var(--primary-color);
    color: var(--primary-color-text);
}
.p-chip.custom-chip4 {
    background: var(--red-500);
    color: var(--primary-color-text);
}
.p-chip.custom-chip5 {
    background: var(--cyan-500);
    color: var(--primary-color-text);
}
.tour-item {
        border: 1px solid var(--surface-border);
        border-radius: 8px;
        transition: 0.25s;
        &:hover {
            box-shadow: 0 3px 12px rgba(0, 0, 0, 0.25);
        }
    }
.tour-title {
	&:hover {
        text-decoration: underline;
    }
}
.old-price {
    text-decoration: line-through;
    text-decoration-thickness: 1px;
}

.tour-list-layout-config {
	position: fixed;
	top: 0;
	padding: 0;
	left: 0;
    height: 100vh;

	.tour-list-layout-config-button {
		display: block;
		position: absolute;
		width: 48px;
		height: 48px;
		line-height: 48px;
		background-color: var(--primary-color);
		color: var(--primary-color-text);
		text-align: center;
		bottom: 200px;
		right: -48px;
		z-index: 999;
		overflow: hidden;
		cursor: pointer;
		border-top-right-radius: 12px;
		border-bottom-right-radius: 12px;
		transition: background-color 0.25s;

		i {
			font-size: 24px;
			line-height: inherit;
			cursor: pointer;
			transform: rotate(0deg);
			transition: color 0.25s, transform 1s;
		}
	}
}
</style>